@font-face {
font-family: '__builderSans_a08e9a';
src: url(/_next/static/media/3ca2abec930f1eb7-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__builderSans_a08e9a';
src: url(/_next/static/media/b496136a597d6048-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__builderSans_a08e9a';
src: url(/_next/static/media/575eb3b900ffeaf9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__builderSans_Fallback_a08e9a';src: local("Arial");ascent-override: 94.49%;descent-override: 27.00%;line-gap-override: 0.00%;size-adjust: 103.72%
}.__className_a08e9a {font-family: '__builderSans_a08e9a', '__builderSans_Fallback_a08e9a'
}

/* lato-latin-ext-400-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/lato-latin-ext-400-normal.6ebed106.woff2) format('woff2'), url(/_next/static/media/lato-latin-ext-400-normal.1533dda9.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* lato-latin-400-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(/_next/static/media/lato-latin-400-normal.b7ffde23.woff2) format('woff2'), url(/_next/static/media/lato-latin-400-normal.c97328b9.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
/* lato-latin-ext-700-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/lato-latin-ext-700-normal.8697d1d5.woff2) format('woff2'), url(/_next/static/media/lato-latin-ext-700-normal.1586d9d8.woff) format('woff');
  unicode-range: U+0100-02AF,U+0304,U+0308,U+0329,U+1E00-1E9F,U+1EF2-1EFF,U+2020,U+20A0-20AB,U+20AD-20C0,U+2113,U+2C60-2C7F,U+A720-A7FF;
}

/* lato-latin-700-normal */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url(/_next/static/media/lato-latin-700-normal.d5eb20bc.woff2) format('woff2'), url(/_next/static/media/lato-latin-700-normal.3fc09a61.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}
